import React from 'react';

import classNames from 'classnames';
import { snakeToPascal } from '../../utils/caseconverters';
import dynamic from 'next/dynamic';

const CardCta = dynamic(() => import('../CardCta'));
const CardCtaSmallList = dynamic(() => import('../CardCtaSmallList'));
const CardOfferList = dynamic(() => import('../CardOfferList'));
const CardTestimonial = dynamic(() => import('../CardTestimonial'));
const Carousel = dynamic(() => import('../Carousel'));
const CtaButtonList = dynamic(() => import('../CtaButtonList'));
const Diagrams = dynamic(() => import('../Diagrams'));
const Faq = dynamic(() => import('../Faq'));
const InlineRichText = dynamic(() => import('../InlineRichText'));
const News = dynamic(() => import('../News'));
const ProductCalculator = dynamic(() => import('../ProductCalculator'));
const ProductDiagram = dynamic(() => import('../ProductDiagram'));
const ProductGuide = dynamic(() => import('../ProductGuide'));
const ProductList = dynamic(() => import('../ProductList'));
const TabbedContent = dynamic(() => import('../TabbedContent'));
const TextColumns = dynamic(() => import('../TextColumns'));
const TextColumnWithImage = dynamic(() => import('../TextColumnWithImage'));
const UspList = dynamic(() => import('../UspList'));
const Components = {
    CardCta,
    CardCtaSmallList,
    CardOfferList,
    CardTestimonial,
    Carousel,
    CtaButtonList,
    Diagrams,
    Faq,
    InlineRichText,
    News,
    ProductCalculator,
    ProductDiagram,
    ProductGuide,
    ProductList,
    TabbedContent,
    TextColumns,
    TextColumnWithImage,
    UspList,
};

import styles from './GlobalModules.module.scss';

const GlobalModules = ({ block = [] }) => {
    if (!block.length) {
        return null;
    }

    const dynamicComponents = block.map((item, index) => {
        item.component = snakeToPascal(item.component);
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        const isFirstComponent = index === 0;

        const isFullHeightComponent =
            item.component === 'CardCtaSmallList' ||
            item.component === 'ProductList' ||
            item.component === 'TextColumns';

        const spaceClass =
            isFirstComponent && isFullHeightComponent ? 'Space' : '';

        return (
            <div
                key={index}
                className={classNames(
                    styles['GlobalModules__Component'],
                    styles['GlobalModules__Component--' + item.component],
                    styles['GlobalModules__Component--' + spaceClass]
                )}>
                <Component id={item.id} {...item} />
            </div>
        );
    });

    return <>{dynamicComponents}</>;
};

GlobalModules.propTypes = {};

export default GlobalModules;
